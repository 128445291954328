
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
@Component({ name: 'Exwarehouse' })
@KeepAlive
export default class extends Vue {
  private page = 1
  private size = 10
  private total = 0
  private loading = false
  private tableData = []

  private dateRange = []
  private searchForm = {
    projectName: '', // 项目名称
    checkoutNum: '', // 出库单号
    startTime: '', // 出库日期
    endTime: '' // 出库日期
  }

  created () {
    this.loadData()
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.material.selectYhCheckoutByPage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  dateRangeChange (date: any) {
    if (date) {
      this.searchForm.startTime = date[0]
      this.searchForm.endTime = date[1]
    } else {
      this.searchForm.startTime = ''
      this.searchForm.endTime = ''
    }
  }

  // 下载
  onDownLoad (id: string) {
    const loading = this.$loading({
      lock: true,
      text: '下载中...',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    })
    this.$axios.downloadFile(this.$apis.material.exportExcel2, {
      id: id
    }).finally(() => {
      loading.close()
    })
  }
}
